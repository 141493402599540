import "./card.scss";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Photo } from "../../types/shared";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";

export type CardType = {
  title: string | null | undefined;
  photo: Photo | null | undefined;
  text: Record<string, unknown> | null | undefined;
};

type PropsCard = PropsWithChildren<{ card: CardType; is_portrait?: boolean }>;

const Card: React.FC<PropsCard> = (props: PropsCard) => {
  if (!props.card.title || !props.card.text || !props.card.photo)
    return <DoNotMountComponent sms={"Faltan datos para montar la tarjeta"} />;
  return (
    <div className={`m-card ${props.is_portrait ? "m-card--portrait" : ""}`}>
      <SanityImage
        {...props.card.photo.image}
        alt={props.card.photo.alt}
        className={"a-card__photo"}
        style={{ maxWidth: "538px" }}
        width={538}
        height={358}
      />
      <div className="m-card__info">
        <h3 className={"a-card__title"}>{props.card.title}</h3>
        <div className="m-card__text">
          <BlockContent blocks={props.card.text} />
        </div>
      </div>
    </div>
  );
};

export default Card;
