import "../styles/layouts/about-us.scss";
import "../styles/layouts/about-us-elements.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { waveGallery_svg, waveHero_svg, waveOurHistory_svg, waveWeTalk_svg } from "../assets/svg/about-us_elements";
import Gallery from "../components/Gallery/Gallery";
import HeroAboutUs from "../components/Hero/HeroAboutUs/HeroAboutUs";
import InfrastructureGoals from "../components/Infrastructure/Goals/InfrastructureGoals";
import InfrastructureResume from "../components/Infrastructure/Resume/InfrastructureResume";
import Layout from "../components/Layout/layout";
import MVV from "../components/MMV/MVV";
import OurHistory from "../components/OurHistory/OurHistory";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import WeTalk from "../components/WeTalk/WeTalk";
import { Photo } from "../types/shared";

const AboutUs = ({ data }: PageProps<Queries.AboutUsQuery>) => {
  return (
    <Layout>
      <div className="l-about-us">
        <HeroAboutUs />
        <div className="e-about-us__hero">{waveHero_svg}</div>
        <InfrastructureResume />
        <InfrastructureGoals />
        <Gallery photosList={data.sanityPageAboutUs?.gallery as Photo[]} />
        <div className="e-about-us__gallery">{waveGallery_svg}</div>
        <OurHistory />
        <div className="e-about-us__our-history">{waveOurHistory_svg}</div>
        <MVV />
        <div className="e-about-us__we-talk">{waveWeTalk_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default AboutUs;

export const Head = ({ data }: PageProps<Queries.AboutUsQuery>) => (
  <SEO
    title={data.sanityPageAboutUs?.seo?.title}
    robots={data.sanityPageAboutUs?.seo?.seo_robots}
    description={data.sanityPageAboutUs?.seo?.description}
    canonical={data.sanityPageAboutUs?.seo?.canonical}
    imageAbsolutePath={data.sanityPageAboutUs?.seo?.thumbnail?.asset?.url}
  />
);

export const query = graphql`
  query AboutUs {
    sanityPageAboutUs {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      gallery {
        alt
        image {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
    }
  }
`;
