import * as React from "react";
// Remember that all const are suffix _svg

const building_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79.394"
    height="48.32"
    viewBox="0 0 79.394 48.32">
    <g
      id="building-svgrepo-com"
      transform="translate(0 -86.947)">
      <g
        id="Group_153"
        data-name="Group 153"
        transform="translate(0 86.947)">
        <path
          id="Path_159"
          data-name="Path 159"
          d="M78.054,102.849H61.114V98.226a1.34,1.34,0,0,0-1.34-1.34H38.664v-8.6a1.34,1.34,0,0,0-1.34-1.34H1.34A1.34,1.34,0,0,0,0,88.287v45.64a1.34,1.34,0,0,0,1.34,1.34H78.054a1.34,1.34,0,0,0,1.34-1.34V104.19A1.34,1.34,0,0,0,78.054,102.849ZM2.68,89.627h33.3v42.959H2.68Zm35.983,40.407h2.547v-2.68H38.664v-2.3h2.547v-2.68H38.664v-2.3h2.547V117.4H38.664v-2.3h2.547v-2.68H38.664v-2.3h2.547v-2.68H38.664v-2.3h2.547v-2.68H38.664v-2.9h19.77v33.02H38.664Zm38.05,2.553h-15.6V105.53h15.6Z"
          transform="translate(0 -86.947)"
          fill="#fff"
        />
        <rect
          id="Rectangle_152"
          data-name="Rectangle 152"
          width="4.391"
          height="2.68"
          transform="translate(43.857 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_153"
          data-name="Rectangle 153"
          width="4.391"
          height="2.68"
          transform="translate(50.894 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_154"
          data-name="Rectangle 154"
          width="4.391"
          height="2.68"
          transform="translate(43.857 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_155"
          data-name="Rectangle 155"
          width="4.391"
          height="2.68"
          transform="translate(50.894 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_156"
          data-name="Rectangle 156"
          width="4.391"
          height="2.68"
          transform="translate(43.857 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_157"
          data-name="Rectangle 157"
          width="4.391"
          height="2.68"
          transform="translate(50.894 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_158"
          data-name="Rectangle 158"
          width="4.391"
          height="2.68"
          transform="translate(43.857 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_159"
          data-name="Rectangle 159"
          width="4.391"
          height="2.68"
          transform="translate(50.894 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_160"
          data-name="Rectangle 160"
          width="4.391"
          height="2.68"
          transform="translate(43.857 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="4.391"
          height="2.68"
          transform="translate(50.894 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="4.391"
          height="2.68"
          transform="translate(43.857 40.407)"
          fill="#fff"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="4.391"
          height="2.68"
          transform="translate(50.894 40.407)"
          fill="#fff"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="4.391"
          height="2.68"
          transform="translate(63.442 20.801)"
          fill="#fff"
        />
        <rect
          id="Rectangle_165"
          data-name="Rectangle 165"
          width="4.391"
          height="2.68"
          transform="translate(70.479 20.801)"
          fill="#fff"
        />
        <rect
          id="Rectangle_166"
          data-name="Rectangle 166"
          width="4.391"
          height="2.68"
          transform="translate(63.442 25.779)"
          fill="#fff"
        />
        <rect
          id="Rectangle_167"
          data-name="Rectangle 167"
          width="4.391"
          height="2.68"
          transform="translate(70.479 25.779)"
          fill="#fff"
        />
        <rect
          id="Rectangle_168"
          data-name="Rectangle 168"
          width="4.391"
          height="2.68"
          transform="translate(63.442 30.757)"
          fill="#fff"
        />
        <rect
          id="Rectangle_169"
          data-name="Rectangle 169"
          width="4.391"
          height="2.68"
          transform="translate(70.479 30.757)"
          fill="#fff"
        />
        <rect
          id="Rectangle_170"
          data-name="Rectangle 170"
          width="4.391"
          height="2.68"
          transform="translate(63.442 35.735)"
          fill="#fff"
        />
        <rect
          id="Rectangle_171"
          data-name="Rectangle 171"
          width="4.391"
          height="2.68"
          transform="translate(70.479 35.735)"
          fill="#fff"
        />
        <rect
          id="Rectangle_172"
          data-name="Rectangle 172"
          width="4.391"
          height="2.68"
          transform="translate(63.442 40.713)"
          fill="#fff"
        />
        <rect
          id="Rectangle_173"
          data-name="Rectangle 173"
          width="4.391"
          height="2.68"
          transform="translate(70.479 40.713)"
          fill="#fff"
        />
        <rect
          id="Rectangle_174"
          data-name="Rectangle 174"
          width="4.646"
          height="2.68"
          transform="translate(5.84 5.561)"
          fill="#fff"
        />
        <rect
          id="Rectangle_175"
          data-name="Rectangle 175"
          width="4.646"
          height="2.68"
          transform="translate(13.286 5.561)"
          fill="#fff"
        />
        <rect
          id="Rectangle_176"
          data-name="Rectangle 176"
          width="4.646"
          height="2.68"
          transform="translate(20.732 5.561)"
          fill="#fff"
        />
        <rect
          id="Rectangle_177"
          data-name="Rectangle 177"
          width="4.646"
          height="2.68"
          transform="translate(28.177 5.561)"
          fill="#fff"
        />
        <rect
          id="Rectangle_178"
          data-name="Rectangle 178"
          width="4.646"
          height="2.68"
          transform="translate(5.84 10.539)"
          fill="#fff"
        />
        <rect
          id="Rectangle_179"
          data-name="Rectangle 179"
          width="4.646"
          height="2.68"
          transform="translate(13.286 10.539)"
          fill="#fff"
        />
        <rect
          id="Rectangle_180"
          data-name="Rectangle 180"
          width="4.646"
          height="2.68"
          transform="translate(20.732 10.539)"
          fill="#fff"
        />
        <rect
          id="Rectangle_181"
          data-name="Rectangle 181"
          width="4.646"
          height="2.68"
          transform="translate(28.177 10.539)"
          fill="#fff"
        />
        <rect
          id="Rectangle_182"
          data-name="Rectangle 182"
          width="4.646"
          height="2.68"
          transform="translate(5.84 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_183"
          data-name="Rectangle 183"
          width="4.646"
          height="2.68"
          transform="translate(13.286 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_184"
          data-name="Rectangle 184"
          width="4.646"
          height="2.68"
          transform="translate(20.732 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_185"
          data-name="Rectangle 185"
          width="4.646"
          height="2.68"
          transform="translate(28.177 15.517)"
          fill="#fff"
        />
        <rect
          id="Rectangle_186"
          data-name="Rectangle 186"
          width="4.646"
          height="2.68"
          transform="translate(5.84 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_187"
          data-name="Rectangle 187"
          width="4.646"
          height="2.68"
          transform="translate(13.286 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_188"
          data-name="Rectangle 188"
          width="4.646"
          height="2.68"
          transform="translate(20.732 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_189"
          data-name="Rectangle 189"
          width="4.646"
          height="2.68"
          transform="translate(28.177 20.495)"
          fill="#fff"
        />
        <rect
          id="Rectangle_190"
          data-name="Rectangle 190"
          width="4.646"
          height="2.68"
          transform="translate(5.84 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_191"
          data-name="Rectangle 191"
          width="4.646"
          height="2.68"
          transform="translate(13.286 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_192"
          data-name="Rectangle 192"
          width="4.646"
          height="2.68"
          transform="translate(20.732 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_193"
          data-name="Rectangle 193"
          width="4.646"
          height="2.68"
          transform="translate(28.177 25.473)"
          fill="#fff"
        />
        <rect
          id="Rectangle_194"
          data-name="Rectangle 194"
          width="4.646"
          height="2.68"
          transform="translate(5.84 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_195"
          data-name="Rectangle 195"
          width="4.646"
          height="2.68"
          transform="translate(13.286 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_196"
          data-name="Rectangle 196"
          width="4.646"
          height="2.68"
          transform="translate(20.732 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_197"
          data-name="Rectangle 197"
          width="4.646"
          height="2.68"
          transform="translate(28.177 30.451)"
          fill="#fff"
        />
        <rect
          id="Rectangle_198"
          data-name="Rectangle 198"
          width="4.646"
          height="2.68"
          transform="translate(5.84 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_199"
          data-name="Rectangle 199"
          width="4.646"
          height="2.68"
          transform="translate(13.286 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_200"
          data-name="Rectangle 200"
          width="4.646"
          height="2.68"
          transform="translate(20.732 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_201"
          data-name="Rectangle 201"
          width="4.646"
          height="2.68"
          transform="translate(28.177 35.429)"
          fill="#fff"
        />
        <rect
          id="Rectangle_202"
          data-name="Rectangle 202"
          width="4.646"
          height="2.68"
          transform="translate(5.84 40.407)"
          fill="#fff"
        />
        <rect
          id="Rectangle_203"
          data-name="Rectangle 203"
          width="4.646"
          height="2.68"
          transform="translate(13.286 40.407)"
          fill="#fff"
        />
        <rect
          id="Rectangle_204"
          data-name="Rectangle 204"
          width="4.646"
          height="2.68"
          transform="translate(20.732 40.407)"
          fill="#fff"
        />
        <rect
          id="Rectangle_205"
          data-name="Rectangle 205"
          width="4.646"
          height="2.68"
          transform="translate(28.177 40.407)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const ice_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="63.987"
    viewBox="0 0 56 63.987">
    <path
      id="cold-svgrepo-com"
      d="M68.625,45.007l-6.5-3.752,5.632-3.321a2.184,2.184,0,1,0-2.218-3.762l-7.758,4.575-4.566-2.636V27.876l4.563-2.635,7.761,4.574a2.184,2.184,0,0,0,2.218-3.763l-5.633-3.32,6.5-3.753A2.183,2.183,0,0,0,66.441,15.2l-6.5,3.752-.059-6.539a2.184,2.184,0,0,0-4.367.039l.081,9.008-4.565,2.636L43.9,19.976v-5.27l7.842-4.432a2.184,2.184,0,1,0-2.149-3.8L43.9,9.689V2.184a2.184,2.184,0,0,0-4.367,0V9.689L33.84,6.472a2.184,2.184,0,0,0-2.149,3.8l7.842,4.432v5.27L32.4,24.094l-4.566-2.636.081-9.008a2.183,2.183,0,0,0-2.164-2.2h-.02a2.184,2.184,0,0,0-2.183,2.164l-.059,6.539-6.5-3.752a2.183,2.183,0,0,0-2.184,3.782l6.5,3.753-5.634,3.32a2.184,2.184,0,1,0,2.217,3.763l7.761-4.574,4.564,2.635v8.235l-4.566,2.637-7.759-4.575a2.184,2.184,0,1,0-2.218,3.762l5.633,3.321-6.5,3.752a2.183,2.183,0,1,0,2.184,3.782l6.5-3.752.059,6.539a2.184,2.184,0,0,0,2.183,2.164h.02a2.184,2.184,0,0,0,2.164-2.2l-.081-9.009L32.4,39.893l7.132,4.118v5.271l-7.842,4.432a2.184,2.184,0,1,0,2.149,3.8L39.533,54.3V61.8a2.184,2.184,0,0,0,4.367,0V54.3l5.693,3.218a2.184,2.184,0,1,0,2.149-3.8L43.9,49.281V44.011l7.132-4.118L55.6,42.528l-.083,9.008a2.184,2.184,0,0,0,2.163,2.2h.02a2.184,2.184,0,0,0,2.183-2.163l.061-6.541,6.5,3.753a2.183,2.183,0,1,0,2.183-3.782ZM34.585,27.876l7.132-4.118,7.132,4.118v8.235l-7.132,4.118-7.132-4.118Z"
      transform="translate(-13.717)"
      fill="#fff"
    />
  </svg>
);

const structure_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="55.478"
    viewBox="0 0 58 55.478">
    <g
      id="pallet-svgrepo-com"
      transform="translate(0 -11.131)">
      <g
        id="Group_152"
        data-name="Group 152"
        transform="translate(0 11.131)">
        <path
          id="Path_158"
          data-name="Path 158"
          d="M56.739,18.7A1.261,1.261,0,0,0,58,17.435V12.392a1.261,1.261,0,0,0-1.261-1.261H1.261A1.261,1.261,0,0,0,0,12.392v5.044A1.261,1.261,0,0,0,1.261,18.7H5.674V23.74H1.261A1.261,1.261,0,0,0,0,25V35.087a1.261,1.261,0,0,0,1.261,1.261H5.674v5.044H1.261A1.261,1.261,0,0,0,0,42.653V52.739A1.261,1.261,0,0,0,1.261,54H5.674v5.044H1.261A1.261,1.261,0,0,0,0,60.3v5.044a1.261,1.261,0,0,0,1.261,1.261H56.739A1.261,1.261,0,0,0,58,65.348V60.3a1.261,1.261,0,0,0-1.261-1.261H52.326V54h4.413A1.261,1.261,0,0,0,58,52.739V42.653a1.261,1.261,0,0,0-1.261-1.261H52.326V36.348h4.413A1.261,1.261,0,0,0,58,35.087V25a1.261,1.261,0,0,0-1.261-1.261H52.326V18.7Zm-1.261,42.87v2.522H2.522V61.566ZM8.2,59.044V54h3.783v5.044Zm6.3,0V54H24.587v5.044Zm12.609,0V54h3.783v5.044Zm6.3,0V54H43.5v5.044Zm12.609,0V54H49.8v5.044Zm9.456-7.565H2.522V48.957H55.478v2.522Zm0-7.565v2.522H2.522V43.914ZM8.2,41.392V36.348h3.783v5.044Zm6.3,0V36.348H24.587v5.044Zm12.609,0V36.348h3.783v5.044Zm6.3,0V36.348H43.5v5.044Zm12.609,0V36.348H49.8v5.044Zm9.456-7.565H2.522V31.3H55.478v2.522Zm0-7.565v2.522H2.522V26.261ZM8.2,23.74V18.7h3.783V23.74Zm6.3,0V18.7H24.587V23.74Zm12.609,0V18.7h3.783V23.74Zm6.3,0V18.7H43.5V23.74Zm12.609,0V18.7H49.8V23.74Zm-43.5-7.565V13.653H55.478v2.522Z"
          transform="translate(0 -11.131)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const truck_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.265"
    height="52.25"
    viewBox="0 0 64.265 52.25">
    <g
      id="truck-svgrepo-com_1_"
      data-name="truck-svgrepo-com (1)"
      transform="translate(-11.2 -57)">
      <path
        id="Path_157"
        data-name="Path 157"
        d="M62.36,65.89h-11.9V57H14.89a3.676,3.676,0,0,0-3.69,3.69V95.029a3.676,3.676,0,0,0,3.69,3.69h4.255a8.484,8.484,0,0,0-.158,1.641,8.872,8.872,0,0,0,8.89,8.89,8.781,8.781,0,0,0,8.89-8.89,8.484,8.484,0,0,0-.158-1.641H52.932a8.484,8.484,0,0,0-.158,1.641,8.89,8.89,0,1,0,17.78,0,8.484,8.484,0,0,0-.158-1.641h1.379a3.711,3.711,0,0,0,3.69-3.834V82.16L62.36,65.89ZM27.89,106.663a6.29,6.29,0,1,1,0-12.58,6.274,6.274,0,0,1,6.29,6.29A6.368,6.368,0,0,1,27.89,106.663Zm7.748-10.676a8.9,8.9,0,0,0-15.5,0H14.89a.932.932,0,0,1-.959-.959V60.7a.932.932,0,0,1,.959-.959H47.863V96H35.638Zm26.04,10.663a6.29,6.29,0,1,1,6.29-6.29A6.368,6.368,0,0,1,61.677,106.65ZM72.891,94.884a1.172,1.172,0,0,1-1.09,1.09H69.425a8.9,8.9,0,0,0-15.5,0H50.594v-27.5H61.126L72.891,83.118Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export { building_svg, ice_svg, structure_svg, truck_svg };
