import "./our-history.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import Card, { CardType } from "../Card/Card";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";

const OurHistory: React.FC = () => {
  const data: Queries.ourHistoryQueryQuery = useStaticQuery(graphql`
    query ourHistoryQuery {
      sanityPageAboutUs {
        ourHistory {
          title
          text: _rawResume
          photo {
            alt
            image {
              asset {
                gatsbyImageData
                _id
              }
            }
          }
        }
      }
    }
  `);

  if (!data.sanityPageAboutUs?.ourHistory) return <DoNotMountComponent sms={"Nuestra historia"} />;

  const { ourHistory } = data.sanityPageAboutUs;
  return (
    <section className={"o-our-history"}>
      <div className={"c-our-history"}>
        <Card card={ourHistory as CardType} />
      </div>
    </section>
  );
};

export default OurHistory;
