import "./counter.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

type PropsCounter = PropsWithChildren<{
  currentPosition: number;
  totalPositions: number;
  is_vertical?: boolean;
}>;

const Counter: (props: PropsCounter) => JSX.Element = (props: PropsCounter) => {
  return (
    <div className={`m-counter ${props.is_vertical ? "m-counter--vertical" : ""}`}>
      <p className={`m-counter__numeration`}>
        {props.currentPosition} <sup className={"a-counter__denominator"}>/ {props.totalPositions}</sup>
      </p>
      <meter
        className={"a-counter__meter"}
        min={0}
        max={props.totalPositions}
        value={props.currentPosition}
      />
    </div>
  );
};

export default Counter;
