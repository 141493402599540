import * as React from "react";
import { useEffect, useState } from "react";

import { getWidthScreen } from "../../utils/window";

export const enum MoveBy {
  ITEMS,
  WINDOW,
}

export const enum AlignItem {
  LEFT,
  CENTER,
  RIGHT,
}

const useSlider = (ref: React.MutableRefObject<HTMLDivElement>, moveBy: MoveBy, alignItem?: AlignItem) => {
  const [move, setMove] = useState(350);
  const [isCanNext, setIsCanNext] = useState(true);
  const [isCanPrev, setIsCanPrev] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [totalsPositions, setTotalsPositions] = useState<number>(1);
  const [index, setIndex] = useState<number>(0);

  function onClickCard(position: number) {
    if (position === index) {
      return;
    }
    if (position > index) {
      next();
    }
    if (position < index) {
      prev();
    }
    setIndex(position);
  }

  function endOfStroke() {
    if (!ref.current) {
      return;
    }
    const maxLeft = ref.current.scrollWidth - ref.current.clientWidth;
    const offsetMiddleElement: number = ref.current.scrollWidth / getWidthScreen() / 2;
    let calculatePosition = (ref.current.scrollLeft * Math.round(ref.current.scrollWidth / getWidthScreen())) / maxLeft;
    if (alignItem === AlignItem.CENTER) {
      calculatePosition += offsetMiddleElement;
    }
    const roundedPosition = Math.floor(calculatePosition);
    setCurrentPosition(roundedPosition);

    if (ref.current.scrollLeft < 10) {
      setIsCanPrev(false);
    } else {
      setIsCanPrev(true);
    }
    if (ref.current.scrollWidth - ref.current.scrollLeft <= ref.current.clientWidth + 10) {
      setIsCanNext(false);
    } else {
      setIsCanNext(true);
    }
  }

  function calculateMove(): void {
    if (!ref.current) return;
    setTotalsPositions(Math.round(ref.current.scrollWidth / getWidthScreen()) + 1);
    if (ref.current && ref.current.children.length > 0) {
      // const element = ref.current.children[0] as HTMLDivElement;
      // setMove((ref.current.scrollWidth - element.offsetWidth) / ref.current.children.length);
      setMove(ref.current.offsetWidth);
      // setMove(getWidthScreen());
    }
  }

  function next() {
    setMandatoryUnset();
    if (moveBy === MoveBy.ITEMS) {
      if (alignItem === AlignItem.CENTER) {
        const nextElement = ref.current.children[currentPosition] as HTMLDivElement;
        ref.current.scrollLeft = nextElement.offsetLeft - (getWidthScreen() / 2 - nextElement.offsetWidth / 2);
      } else {
        const nextElement = ref.current.children[currentPosition + 1] as HTMLDivElement;
        if (nextElement) {
          ref.current.scrollLeft = nextElement.offsetLeft - 20;
        }
      }
    } else {
      goto(+1);
    }
  }
  //
  // useEffect(() => {
  //   console.log("this is new position", currentPosition);
  // }, [currentPosition]);

  function prev() {
    setMandatoryUnset();
    if (moveBy === MoveBy.ITEMS) {
      let nextPosition = currentPosition;
      if (currentPosition - 2 < 1) {
        nextPosition = 0;
      } else {
        nextPosition = currentPosition - 2;
      }
      const nextElement = ref.current.children[nextPosition] as HTMLDivElement;
      if (alignItem === AlignItem.CENTER) {
        ref.current.scrollLeft = nextElement.offsetLeft - (getWidthScreen() / 2 - nextElement.offsetWidth / 2);
      } else {
        ref.current.scrollLeft = nextElement.offsetLeft - 20;
      }
    } else {
      goto(-1);
    }
  }

  function handleIntersect() {
    calculateMove();
    // calculateCurrentPosition();
  }

  function createObserver() {
    const options = {
      root: null,
      rootMargin: "0px",
      delay: 500,
      threshold: 0.2,
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(ref.current);
  }
  function handlerResize() {
    if (!ref.current) return;
    ref.current.scrollLeft = 0;
    calculateMove();
  }
  function createOnResize() {
    const onResize = new ResizeObserver(handlerResize);
    onResize.observe(ref.current);
  }

  function setMandatoryX() {
    ref.current.style.scrollSnapType = "x mandatory";
  }
  function setMandatoryUnset() {
    ref.current.style.scrollSnapType = "unset";
  }

  useEffect(() => {
    createObserver();
    createOnResize();
    if (ref.current && ref.current.children.length > 0) {
      ref.current.addEventListener("scroll", endOfStroke, { passive: true });
      // window.addEventListener("keydown", keyboard);
      ref.current.addEventListener("touchmove", setMandatoryX, { passive: true });
      // ref.current.addEventListener("touchend", setMandatoryUnset);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", endOfStroke);
        ref.current.removeEventListener("touchmove", setMandatoryX);
        // ref.current.removeEventListener("touchend", setMandatoryUnset);
      }
    };
  }, []);
  const goto = (index: number) => {
    if (!ref.current) {
      return;
    }
    const positionToSet = ref.current.scrollLeft + move * index;
    if (positionToSet < 320) {
      ref.current.scrollLeft = 0;
    } else {
      ref.current.scrollLeft = positionToSet;
    }
  };

  return { next, prev, isCanNext, isCanPrev, onClickCard, totalsPositions, currentPosition, setCurrentPosition };
};

export default useSlider;
