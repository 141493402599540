import * as React from "react";
// Remember that all const are suffix _svg

const waveWeTalk_svg = (
  <svg
    id="waveWeTalkAboutUs"
    data-name="Group 154"
    xmlns="http://www.w3.org/2000/svg"
    width="747.142"
    height="758.5"
    viewBox="0 0 747.142 758.5">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="747.142"
          height="758.5"
          fill="none"
          stroke="#5cb4e1"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_111"
      data-name="Group 111"
      clipPath="url(#clip-path)">
      <path
        id="Path_111"
        data-name="Path 111"
        d="M42.162,114.447c-13.781,18.759-21.829,39.921-19.249,63.464,5.048,46.094,50.6,80.27,54.695,126.459C83.414,369.931,5.077,416.611.684,482.284c-3.1,46.362,33.636,87.919,76.577,105.664,48.373,19.991,107.3,2.592,150.975,28.852,51.1,30.727,49.2,116.234,106.711,135.376,32.89,10.95,68.995-12.911,84.795-43.766s16.835-66.819,19.6-101.378c3.585-44.845,5.9-82.458,50.438-106.607,30.582-16.583,66.114-21,99.008-32.33a247.781,247.781,0,0,0,137.4-118.2c22.724-43.489,27.516-105.874-12.788-133.856-24.116-16.74-56.566-15.083-84.964-7.64-54.114,14.185-113.613,40.147-168.077,8.943-46.5-26.643-48.373-88.215-73.173-131.7C365.772,48.1,332.313,15.393,290.48,4.58,231.195-10.742,170.541,19.8,118,51.251,90.329,67.821,60.929,88.9,42.162,114.447Z"
        transform="translate(1.562 1.561)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Path_112"
        data-name="Path 112"
        d="M40.559,112.056c-12.969,17.8-20.613,37.839-18.483,60.073,2.959,31.126,24.916,56.8,38.746,84.865,5.543,11.238,9.779,22.859,10.958,35.371C77.607,354.374,5.324,399.087,5.509,460.594c.066,23.333,9.6,43.535,22.489,63.551,12.532,19.46,29.428,35.4,50.611,45.632,42.6,20.581,96.173,8.151,137.96,26.981q3.5,1.576,6.89,3.453c49.951,27.731,51.513,103.162,107.209,121.641,31.679,10.513,65.887-9.8,81.77-36.747s18.8-58.762,22.967-90.836c4.941-37.757,7.418-71.8,37.291-95.913a99.277,99.277,0,0,1,10.15-7.191c27.182-16.888,58.515-22.575,88.1-34.806,52.7-21.784,99.948-64.713,128.8-116.992,11.889-21.541,20.853-46.691,21.359-71.48.495-27.125-10.092-53.207-32.836-67.26-24.018-14.844-54.682-12.977-81.885-5.135-44.507,12.837-92.406,34.217-138.8,21.388a103.292,103.292,0,0,1-22.732-9.309C399.882,182.6,395.9,124.1,371.018,82.838,349.79,47.648,317.547,17.206,277.56,7.171,220.809-7.063,162.74,21.343,112.677,51.394,86.224,67.273,58.4,87.519,40.559,112.056Z"
        transform="translate(17.194 10.578)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Path_113"
        data-name="Path 113"
        d="M39.246,109.667c-12.161,16.843-19.394,35.758-17.716,56.685,2.32,29.354,21.99,53.874,34.58,80.542,5.048,10.661,8.951,21.664,10.134,33.467C72.1,338.818,6.393,381.8,10.621,438.906c1.756,23.68,12.4,41.919,24.042,63.637,10.929,20.4,24.7,37.419,45.575,49.065,39.422,21.99,91.261,11.976,132.01,28.922q3.412,1.422,6.726,3.091c48.806,24.735,53.829,90.09,107.708,107.906,30.471,10.08,62.784-6.684,78.741-29.721s20.774-50.705,26.338-80.3c6.631-34.852,9.388-68.241,35.552-92.336A94.475,94.475,0,0,1,476.2,481.9c23.787-17.2,50.928-24.154,77.2-37.287,46.881-23.432,91.31-66,120.19-115.777,13.031-22.46,24.186-47.907,25.39-74.055,1.187-28.25-11.176-56.211-35.552-69.576-23.807-13.047-52.8-10.871-78.807-2.629-42.562,13.5-87.943,35.4-132.933,23.832a98.483,98.483,0,0,1-22.064-8.613c-43.423-23.3-49.5-78.72-74.48-117.755C334.116,47.192,303.072,19.02,264.931,9.76c-54.221-13.138-109.7,13.13-157.288,41.775C82.406,66.73,56.155,86.136,39.246,109.667Z"
        transform="translate(32.538 19.598)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M38.163,107.276c-11.349,15.887-18.178,33.677-16.946,53.3,1.673,27.578,19.06,50.944,30.413,76.223,4.55,10.08,8.118,20.469,9.309,31.563,5.872,54.9-52.667,96.3-44.973,148.856,3.536,24.149,15.248,40.254,25.588,63.724,9.38,21.3,19.966,39.352,40.547,52.5,36.278,23.169,86.344,15.8,126.051,30.867q3.332,1.261,6.565,2.728c47.66,21.739,56.141,77.014,108.206,94.166,29.259,9.647,59.681-3.569,75.712-22.7s22.748-42.645,29.713-69.757c8.32-31.95,11.358-64.709,33.813-88.755a93.219,93.219,0,0,1,7.628-7.344c20.436-17.449,43.345-25.744,66.291-39.764,41.087-25.105,82.808-67.235,111.59-114.565,14.209-23.37,27.59-49.119,29.416-76.631,1.953-29.371-12.174-59.269-38.268-71.888-23.49-11.362-50.911-8.765-75.724-.124-40.621,14.164-83.48,36.591-127.068,26.268a93.174,93.174,0,0,1-21.388-7.912C372.747,166.4,364.6,114.055,339.5,77.25c-20.815-30.52-50.677-56.417-86.971-64.9C200.846.3,147.956,24.435,102.839,51.675,78.821,66.185,54.144,84.754,38.163,107.276Z"
        transform="translate(47.654 28.616)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M37.271,104.887c-10.5,14.951-16.958,31.6-16.179,49.906,1.03,25.81,16.134,48.023,26.251,71.908,4.047,9.5,7.286,19.274,8.481,29.655,5.893,51.352-44.9,91.257-34.32,139.172,5.452,24.706,18.141,38.548,27.133,63.81,7.884,22.147,15.244,41.19,35.519,55.931,33.207,24.133,81.428,19.624,120.1,32.808q3.245,1.1,6.4,2.37c46.51,18.743,58.453,63.938,108.705,80.431,28.048,9.211,56.578-.457,72.683-15.677s24.664-34.6,33.084-59.219c9.944-29.074,13.332-61.206,32.074-85.174a96.416,96.416,0,0,1,6.371-7.426c17.057-17.745,35.767-27.347,55.383-42.237,35.309-26.807,74.414-68.414,102.989-113.354,15.425-24.265,30.933-50.335,33.446-79.211,2.65-30.492-13.109-62.4-40.984-74.2-23.1-9.779-49.032-6.66-72.65,2.382-38.676,14.828-79.013,37.782-121.2,28.711a88.874,88.874,0,0,1-20.721-7.212c-40.353-19.958-50.553-69.238-75.786-103.809-20.585-28.192-49.284-51.806-83.732-59.512C191.167,3.987,140.866,25.981,98.225,51.82,75.428,65.642,52.366,83.4,37.271,104.887Z"
        transform="translate(62.574 37.636)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M39.315,102.5c-9.693,14-15.742,29.515-15.413,46.518.387,24.034,13.208,45.1,22.085,67.585,3.552,8.926,6.458,18.079,7.657,27.751,5.918,47.8-36.583,86.2-23.667,129.488,7.546,25.307,21.092,36.817,28.678,63.9,6.441,22.975,10.562,42.937,30.492,59.36,30.232,24.912,76.511,23.453,114.141,34.753q3.153.946,6.239,2.011c45.36,15.747,60.761,50.866,109.2,66.691,26.84,8.778,53.475,2.662,69.654-8.654s26.63-26.552,36.459-48.678c11.625-26.181,15.289-57.74,30.335-81.6,1.6-2.543,3.26-5.061,5.11-7.5,13.678-18.05,28.192-28.975,44.479-44.718,29.531-28.547,66.106-69.534,94.38-112.138,16.686-25.138,34.275-51.546,37.477-81.786,3.35-31.617-13.979-65.62-43.7-76.511-22.653-8.3-47.145-4.558-69.567,4.888C486.623,159.342,448.8,182.824,408.022,175a83.943,83.943,0,0,1-20.045-6.511c-38.816-18.289-51.047-64.507-76.441-96.832-20.329-25.876-47.887-47.2-80.488-54.126-46.617-9.866-94.331,9.994-134.5,34.427C74.967,65.094,53.492,82.023,39.315,102.5Z"
        transform="translate(74.562 46.653)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M42.13,100.108c-8.877,13.039-14.523,27.438-14.642,43.127-.256,22.266,10.278,42.171,17.918,63.27a122.982,122.982,0,0,1,6.829,25.847c5.938,44.244-27.776,81.069-13.018,119.8,9.866,25.884,24.1,35.07,30.232,63.983,5.04,23.795,5.938,44.586,25.46,62.792,27.368,25.522,71.591,27.281,108.186,36.694,2.044.527,4.072,1.071,6.074,1.648,44.215,12.755,63.077,37.794,109.7,52.956C344.5,578.57,369.234,576,385.491,568.6s28.6-18.5,39.83-38.14c13.315-23.288,17.226-54.307,28.6-78.016,1.228-2.563,2.46-5.106,3.845-7.579,10.3-18.367,20.613-30.636,33.574-47.194,23.745-30.339,57.88-70.61,85.78-110.926,17.984-25.987,37.609-52.762,41.507-84.362,4.035-32.742-14.8-68.962-46.415-78.823-22.167-6.919-45.266-2.452-66.489,7.389C470.933,147.1,435.636,171.1,396.26,164.532a79.569,79.569,0,0,1-19.373-5.811c-37.283-16.616-51.517-59.792-77.1-89.855-20.053-23.572-46.49-42.591-77.245-48.744-44.083-8.77-89.208,8.951-126.9,31.979C75.279,64.551,55.379,80.644,42.13,100.108Z"
        transform="translate(85.776 55.672)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M45,97.717c-8.069,12.083-13.278,25.357-13.876,39.739-.853,20.494,7.356,39.245,13.756,58.952a143.6,143.6,0,0,1,6,23.939c5.963,40.691-18.569,75.79-2.365,110.114,12.441,26.358,27.17,33.323,31.777,64.07,3.688,24.611,1.418,46.139,20.432,66.225,24.632,26.012,66.674,31.106,102.227,38.635,1.99.425,3.96.849,5.914,1.29,43.069,9.754,65.389,24.718,110.2,39.216,24.417,7.912,47.26,8.893,63.6,5.394s30.578-10.451,43.2-27.6c15.009-20.391,19.113-50.9,26.857-74.434.853-2.588,1.665-5.151,2.588-7.653a325.678,325.678,0,0,1,22.666-49.675c17.918-32.181,49.721-71.636,77.179-109.715,19.328-26.8,40.939-53.973,45.534-86.934,4.723-33.871-15.59-72.436-49.131-81.139-21.66-5.621-43.378-.346-63.411,9.895-32.845,16.818-65.62,41.346-103.6,36.03a75.4,75.4,0,0,1-18.7-5.114c-35.746-14.943-51.958-55.1-77.748-82.883-19.756-21.281-45.093-37.98-74-43.353-41.553-7.682-84.09,7.9-119.309,29.531C75.645,64.007,57.319,79.263,45,97.717Z"
        transform="translate(96.94 64.689)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M47.957,95.329c-7.265,11.123-12.066,23.276-13.113,36.352-1.5,18.722,4.43,36.315,9.594,54.629a191.073,191.073,0,0,1,5.172,22.035c5.988,37.143-9.058,70.3,8.287,100.434,15.33,26.634,30.286,31.58,33.323,64.152,2.374,25.435-2.984,47.606,15.4,69.658C128.66,469,168.383,477.52,202.9,483.17q2.9.47,5.753.927c41.915,6.758,67.7,11.642,110.7,25.48,23.206,7.476,44.157,12,60.567,12.417s32.56-2.394,46.572-17.065c16.711-17.49,20.951-47.52,25.122-70.853.466-2.613.861-5.2,1.327-7.735,3.495-19.023,5.366-34.023,11.761-52.148,12.017-34.056,41.606-72.621,68.574-108.5,20.721-27.57,44.211-55.2,49.568-89.509,5.345-35.008-16.369-76.066-51.851-83.451-21.149-4.4-41.5,1.756-60.332,12.4-30.9,17.481-61.156,42.533-97.727,38.466a71.484,71.484,0,0,1-18.03-4.414c-34.213-13.27-52.37-50.429-78.4-75.906C257.063,44.257,232.8,29.9,205.736,25.3c-39.018-6.585-78.963,6.862-111.718,27.084C76.1,63.465,59.348,77.877,47.957,95.329Z"
        transform="translate(108.012 73.708)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M51.091,92.94A79.876,79.876,0,0,0,38.748,125.9c-2.18,16.95,1.5,33.393,5.427,50.314,1.554,6.61,3.128,13.3,4.352,20.131,6,33.587.635,64.523,18.936,90.746,18.565,26.6,33.451,29.853,34.868,64.243,1.088,26.268-7.232,49,10.377,73.087,19.567,26.766,56.842,38.754,90.317,42.521q2.806.315,5.584.564c40.774,3.767,70.012-1.426,111.2,11.745,21.994,7.043,41.054,15.124,57.538,19.439s34.547,5.671,49.947-6.524c18.413-14.584,22.719-44.124,23.379-67.272.074-2.642.058-5.242.07-7.814.07-19.348-2.32-35.721.853-54.625,6.033-35.927,33.545-73.561,59.974-107.292,22.171-28.291,47.545-56.417,53.59-92.085,6.042-36.133-17.127-79.87-54.563-85.767-20.642-3.247-39.612,3.866-57.25,14.906-28.959,18.145-56.689,43.72-91.862,40.91a67.916,67.916,0,0,1-17.358-3.713c-32.672-11.6-52.745-45.81-79.054-68.933C245.983,43.7,222.77,31.718,197.553,27.9c-36.48-5.5-73.841,5.815-104.118,24.632C76.732,62.918,61.542,76.489,51.091,92.94Z"
        transform="translate(118.907 82.724)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M54.6,90.553a85.542,85.542,0,0,0-11.572,29.573c-2.827,15.174-1.426,30.467,1.261,46,1.055,6.033,2.291,12.1,3.523,18.223C53.838,214.378,58.194,242.76,77.4,265.4c22.147,26.115,36.653,28.143,36.418,64.33-.177,27.121-11.308,50.326,5.345,76.516,17.247,27.121,51.925,42.583,84.362,44.466q2.72.161,5.419.206c39.628.771,72.329-14.5,111.7-1.995,20.787,6.61,37.951,18.24,54.509,26.465s36.55,13.764,53.318,4.014c20.14-11.638,24.442-40.691,21.644-63.7-.326-2.658-.75-5.283-1.2-7.888-3.363-19.666-10.039-37.4-10.051-57.105-.017-37.753,25.513-74.455,51.373-106.076,23.68-28.959,50.878-57.633,57.616-94.661,6.738-37.258-17.91-83.859-57.274-88.079-20.164-2.159-37.732,5.967-54.175,17.411-27.009,18.808-52.222,44.907-85.99,43.345a64.56,64.56,0,0,1-16.686-3.012c-31.139-9.932-53.092-41.235-79.709-61.96a132.97,132.97,0,0,0-64.276-27.2c-33.949-4.4-68.723,4.772-96.523,22.188C77.736,62.377,64.074,75.078,54.6,90.553Z"
        transform="translate(129.434 91.742)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M45.237,156.023c3.289,32.148,18.619,64.28,42.937,87.7,26.029,25.06,39.892,26.449,37.967,64.412-1.422,27.99-15.194,51.624.317,79.953,15.058,27.5,47,46.407,78.4,46.407,40.456,0,78.193-28.27,117.454-15.887,19.575,6.173,34.843,21.355,51.48,33.488s38.52,21.825,56.689,14.556c21.829-8.737,26.127-37.2,19.9-60.114s-19.814-44.293-23.408-67.552c-6.112-39.509,17.506-75.3,42.768-104.86s54.216-58.849,61.647-97.236-18.73-88.046-59.994-90.4c-52.14-2.967-79.169,66.2-131.218,65.7-37.432-.359-64.733-35.878-96.375-57.295C183.6,14.131,93.074,30.422,58.948,88.166,46.96,108.445,42.806,132.236,45.237,156.023Z"
        transform="translate(139.11 100.756)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

const waveHero_svg = (
  <svg
    id="about-us-wave-hero_svg"
    data-name="Group 149"
    xmlns="http://www.w3.org/2000/svg"
    width="364.19"
    height="355.201"
    viewBox="0 0 364.19 355.201">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="364.19"
          height="355.201"
          fill="none"
          stroke="#5cb4e1"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_111"
      data-name="Group 111"
      clipPath="url(#clip-path)">
      <path
        id="Path_111"
        data-name="Path 111"
        d="M20.808,53.861c-6.717,8.785-10.641,18.695-9.383,29.72,2.461,21.586,24.666,37.59,26.661,59.22,2.83,30.7-35.355,52.562-37.5,83.316-1.513,21.711,16.4,41.172,37.327,49.482,23.579,9.362,52.3,1.214,73.592,13.511,24.909,14.389,23.983,54.432,52.015,63.4,16.032,5.128,33.631-6.046,41.333-20.5s8.206-31.291,9.554-47.475c1.748-21,2.875-38.615,24.585-49.924,14.907-7.766,32.227-9.835,48.261-15.14,28.523-9.435,53-29.668,66.973-55.352,11.076-20.366,13.413-49.58-6.233-62.684-11.755-7.839-27.573-7.063-41.415-3.578-26.377,6.643-55.38,18.8-81.928,4.188-22.665-12.477-23.579-41.311-35.668-61.676C178.55,22.792,162.24,7.474,141.849,2.411c-28.9-7.175-58.464,7.127-84.073,21.856C44.287,32.026,29.956,41.9,20.808,53.861Z"
        transform="translate(0.505 0.465)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Path_112"
        data-name="Path 112"
        d="M22.594,54.277C16.272,62.612,12.546,72,13.584,82.409c1.442,14.576,12.145,26.6,18.887,39.742a46.148,46.148,0,0,1,5.341,16.564c2.84,29.039-32.394,49.978-32.3,78.781.032,10.927,4.68,20.387,10.962,29.76a60.03,60.03,0,0,0,24.67,21.369c20.767,9.638,46.879,3.817,67.248,12.635q1.708.738,3.359,1.617c24.348,12.986,25.11,48.31,52.258,56.964,15.442,4.923,32.116-4.589,39.858-17.209s9.164-27.518,11.195-42.538c2.409-17.681,3.616-33.622,18.177-44.916a48.43,48.43,0,0,1,4.948-3.368c13.25-7.909,28.523-10.572,42.944-16.3,25.686-10.2,48.719-30.3,62.78-54.787,5.8-10.087,10.164-21.865,10.411-33.473.241-12.7-4.919-24.916-16.006-31.5-11.707-6.951-26.654-6.077-39.914-2.4-21.695,6.012-45.043,16.024-67.658,10.016a51.536,51.536,0,0,1-11.08-4.36C197.743,87.311,195.8,59.918,183.674,40.595,173.327,24.115,157.61,9.86,138.119,5.16,110.456-1.505,82.15,11.8,57.747,25.87,44.853,33.305,31.29,42.787,22.594,54.277Z"
        transform="translate(5.557 3.152)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Path_113"
        data-name="Path 113"
        d="M24.474,54.7c-5.928,7.887-9.453,16.745-8.636,26.545,1.131,13.746,10.719,25.229,16.856,37.717a46.341,46.341,0,0,1,4.94,15.672C40.486,162,8.46,182.132,10.521,208.876c.856,11.089,6.046,19.631,11.719,29.8,5.327,9.555,12.041,17.523,22.215,22.977,19.216,10.3,44.484,5.608,64.347,13.544q1.663.666,3.278,1.447c23.79,11.583,26.239,42.189,52.5,50.532,14.853,4.72,30.6-3.13,38.382-13.918s10.126-23.745,12.838-37.6c3.232-16.321,4.576-31.957,17.33-43.24a45.733,45.733,0,0,1,4.333-3.4c11.595-8.053,24.825-11.311,37.63-17.461,22.852-10.973,44.508-30.909,58.586-54.218,6.352-10.518,11.79-22.435,12.376-34.68.579-13.229-5.448-26.323-17.33-32.582-11.6-6.11-25.737-5.091-38.414-1.231-20.747,6.322-42.867,16.579-64.8,11.16a49.2,49.2,0,0,1-10.755-4.033C193.6,85.056,190.634,59.1,178.457,40.823c-10.251-15.385-25.383-28.577-43.974-32.914-26.429-6.152-53.472,6.149-76.669,19.563C45.512,34.588,32.716,43.676,24.474,54.7Z"
        transform="translate(10.517 5.839)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M26.428,55.112c-5.532,7.44-8.861,15.771-8.26,24.959.816,12.915,9.291,23.857,14.825,35.695a47.074,47.074,0,0,1,4.538,14.781c2.863,25.71-25.672,45.1-21.922,69.709,1.724,11.309,7.432,18.851,12.473,29.841,4.572,9.973,9.733,18.428,19.764,24.585,17.683,10.85,42.088,7.4,61.442,14.455q1.624.59,3.2,1.277c23.232,10.18,27.366,36.065,52.745,44.1,14.262,4.518,29.091-1.671,36.905-10.63s11.089-19.97,14.483-32.667c4.056-14.962,5.536-30.3,16.482-41.563a44.7,44.7,0,0,1,3.718-3.439c9.962-8.171,21.128-12.056,32.313-18.621,20.028-11.757,40.364-31.486,54.394-53.65,6.926-10.944,13.449-23,14.339-35.886.952-13.754-5.934-27.755-18.654-33.664-11.45-5.321-24.816-4.1-36.911-.058-19.8,6.633-40.692,17.135-61.939,12.3a46.61,46.61,0,0,1-10.426-3.705C189.519,82.8,185.548,58.286,173.314,41.051c-10.146-14.293-24.7-26.42-42.393-30.391-25.194-5.643-50.975,5.658-72.967,18.415C46.247,35.869,34.218,44.565,26.428,55.112Z"
        transform="translate(15.403 8.526)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M28.444,55.529c-5.12,7-8.266,14.8-7.886,23.371.5,12.087,7.864,22.489,12.8,33.674a48.55,48.55,0,0,1,4.134,13.887C40.36,150.51,15.6,169.2,20.758,191.635c2.658,11.57,8.843,18.052,13.226,29.882,3.843,10.371,7.43,19.289,17.314,26.192,16.187,11.3,39.691,9.19,58.54,15.364q1.582.515,3.12,1.11c22.671,8.777,28.493,29.942,52.988,37.665,13.672,4.313,27.579-.214,35.429-7.341S213.4,278.3,217.5,266.774c4.847-13.615,6.5-28.662,15.634-39.886a45.7,45.7,0,0,1,3.106-3.478c8.314-8.31,17.434-12.807,27-19.779,17.211-12.554,36.273-32.038,50.2-53.083,7.519-11.363,15.078-23.571,16.3-37.094,1.292-14.279-6.39-29.22-19.977-34.747-11.261-4.58-23.9-3.119-35.413,1.115-18.852,6.944-38.514,17.693-59.076,13.445a44.522,44.522,0,0,1-10.1-3.377c-19.67-9.346-24.642-32.424-36.941-48.613-10.034-13.2-24.023-24.26-40.814-27.869-23.959-5.13-48.478,5.17-69.263,17.27C47.043,37.151,35.8,45.469,28.444,55.529Z"
        transform="translate(20.225 11.213)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M31.409,55.946C26.684,62.5,23.735,69.768,23.9,77.73c.189,11.255,6.438,21.118,10.765,31.65a51.055,51.055,0,0,1,3.732,13c2.885,22.384-17.832,40.369-11.536,60.638,3.678,11.851,10.281,17.241,13.979,29.922,3.14,10.759,5.149,20.107,14.863,27.8,14.736,11.666,37.295,10.983,55.637,16.274q1.537.443,3.041.942c22.111,7.374,29.617,23.82,53.229,31.231,13.083,4.111,26.066,1.247,33.952-4.053s12.981-12.434,17.772-22.8c5.667-12.26,7.453-27.039,14.787-38.211.781-1.191,1.589-2.37,2.491-3.512,6.667-8.453,13.742-13.569,21.681-20.941,14.395-13.368,32.223-32.563,46.005-52.513,8.134-11.772,16.707-24.139,18.268-38.3,1.633-14.806-6.814-30.729-21.3-35.83-11.042-3.889-22.98-2.134-33.91,2.289-17.9,7.252-36.339,18.249-56.218,14.586a42.113,42.113,0,0,1-9.771-3.049c-18.921-8.565-24.883-30.208-37.261-45.346-9.909-12.118-23.342-22.1-39.234-25.347-22.723-4.62-45.981,4.68-65.56,16.122C48.787,38.431,38.319,46.359,31.409,55.946Z"
        transform="translate(24.1 13.899)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M34.622,56.364c-4.327,6.106-7.079,12.849-7.137,20.2-.125,10.427,5.01,19.748,8.734,29.629a55.8,55.8,0,0,1,3.329,12.1c2.895,20.719-13.539,37.964-6.346,56.1,4.809,12.121,11.749,16.423,14.736,29.963,2.457,11.143,2.895,20.879,12.41,29.405,13.34,11.952,34.9,12.776,52.734,17.184,1,.247,1.985.5,2.961.772,21.552,5.973,30.746,17.7,53.474,24.8,12.491,3.908,24.549,2.706,32.474-.762s13.943-8.665,19.415-17.861c6.49-10.906,8.4-25.432,13.941-36.534.6-1.2,1.2-2.391,1.874-3.549,5.02-8.6,10.048-14.347,16.366-22.1C265.162,181.5,281.8,162.644,295.4,143.764c8.766-12.17,18.332-24.708,20.232-39.506,1.967-15.333-7.216-32.294-22.625-36.912C282.2,64.1,270.943,66.2,260.6,70.805c-16.958,7.565-34.163,18.8-53.357,15.728a39.977,39.977,0,0,1-9.443-2.721c-18.173-7.781-25.112-28-37.58-42.079-9.775-11.039-22.661-19.945-37.653-22.826C101.077,14.8,79.081,23.1,60.706,33.883,50.781,39.713,41.081,47.249,34.622,56.364Z"
        transform="translate(27.724 16.587)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M37.854,56.781c-3.933,5.658-6.472,11.874-6.764,18.61-.416,9.6,3.586,18.378,6.705,27.607a65.1,65.1,0,0,1,2.925,11.211c2.907,19.055-9.052,35.492-1.153,51.566,6.065,12.343,13.244,15.6,15.49,30,1.8,11.525.691,21.607,9.96,31.013,12.006,12.181,32.5,14.567,49.83,18.092.97.2,1.93.4,2.883.6,20.994,4.568,31.873,11.575,53.717,18.365,11.9,3.705,23.037,4.165,31,2.526s14.905-4.894,21.058-12.926c7.316-9.549,9.317-23.838,13.091-34.857.416-1.212.812-2.412,1.262-3.584A149.707,149.707,0,0,1,248.9,191.748c8.734-15.07,24.236-33.547,37.62-51.379,9.421-12.548,19.955-25.275,22.2-40.71,2.3-15.862-7.6-33.921-23.949-38-10.558-2.632-21.144-.162-30.909,4.634-16.01,7.876-31.986,19.362-50.5,16.873a37.939,37.939,0,0,1-9.116-2.395c-17.424-7-25.327-25.8-37.9-38.813-9.63-9.966-21.98-17.786-36.072-20.3-20.255-3.6-40.989,3.7-58.156,13.829C52.793,40.995,43.86,48.139,37.854,56.781Z"
        transform="translate(31.333 19.273)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M41.115,57.2a35.476,35.476,0,0,0-6.392,17.023C33.992,82.99,36.882,91.228,39.4,99.8a86.528,86.528,0,0,1,2.521,10.319c2.919,17.394-4.415,32.92,4.04,47.033C53.433,169.629,60.722,171.945,62.2,187.2c1.157,11.911-1.454,22.294,7.509,32.62,10.741,12.368,30.1,16.358,46.927,19l2.8.434c20.431,3.165,33,5.452,53.958,11.932,11.311,3.5,21.524,5.622,29.523,5.815s15.871-1.121,22.7-7.992c8.146-8.19,10.213-22.253,12.246-33.18.227-1.224.42-2.434.647-3.622,1.7-8.908,2.615-15.933,5.733-24.42,5.858-15.948,20.281-34.008,33.426-50.811,10.1-12.911,21.55-25.85,24.162-41.917,2.605-16.394-7.979-35.621-25.275-39.08-10.309-2.061-20.228.822-29.409,5.807-15.062,8.186-29.81,19.918-47.636,18.013a36.022,36.022,0,0,1-8.788-2.067c-16.677-6.214-25.528-23.616-38.217-35.546-9.471-8.906-21.3-15.628-34.491-17.782C99,21.323,79.533,27.62,63.567,37.09,54.833,42.277,46.667,49.026,41.115,57.2Z"
        transform="translate(34.911 21.96)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M44.431,57.616a36.517,36.517,0,0,0-6.016,15.435c-1.063,7.938.733,15.638,2.646,23.562.757,3.1,1.525,6.228,2.121,9.427,2.927,15.728.309,30.216,9.23,42.5,9.05,12.455,16.305,13.98,17,30.085.53,12.3-3.525,22.944,5.058,34.226,9.538,12.534,27.707,18.148,44.024,19.912q1.368.148,2.722.264c19.875,1.764,34.127-.668,54.2,5.5,10.721,3.3,20.011,7.083,28.047,9.1s16.84,2.656,24.346-3.055c8.975-6.83,11.074-20.663,11.4-31.5.036-1.237.028-2.455.034-3.659.034-9.061-1.131-16.728.416-25.58,2.941-16.825,16.352-34.448,29.234-50.244,10.807-13.248,23.175-26.42,26.122-43.123,2.945-16.921-8.348-37.4-26.6-40.164-10.062-1.521-19.308,1.81-27.906,6.98-14.116,8.5-27.633,20.474-44.778,19.158A34.274,34.274,0,0,1,187.27,74.7c-15.926-5.433-25.71-21.452-38.534-32.281a66.134,66.134,0,0,0-32.912-15.259C98.042,24.583,79.83,29.88,65.072,38.692,56.93,43.557,49.526,49.912,44.431,57.616Z"
        transform="translate(38.433 24.646)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M47.869,58.035a39.146,39.146,0,0,0-5.641,13.849c-1.378,7.106-.7,14.267.615,21.539.514,2.825,1.117,5.668,1.718,8.534,2.939,14.065,5.062,27.356,14.423,37.96,10.8,12.23,17.866,13.179,17.752,30.125-.086,12.7-5.512,23.567,2.605,35.832,8.407,12.7,25.311,19.941,41.122,20.823q1.326.075,2.642.1c19.316.361,35.256-6.791,54.446-.934,10.132,3.1,18.5,8.542,26.57,12.394s17.816,6.446,25.99,1.88c9.817-5.45,11.914-19.055,10.55-29.828-.159-1.245-.366-2.474-.583-3.694-1.639-9.209-4.893-17.513-4.9-26.742-.008-17.679,12.436-34.867,25.042-49.675,11.542-13.561,24.8-26.989,28.085-44.329,3.284-17.448-8.73-39.271-27.918-41.247-9.829-1.011-18.392,2.794-26.407,8.154-13.166,8.808-25.455,21.03-41.915,20.3a32.626,32.626,0,0,1-8.134-1.411c-15.178-4.651-25.879-19.31-38.854-29.015a66.222,66.222,0,0,0-31.331-12.737C97.2,27.846,80.246,32.142,66.7,40.3A56.847,56.847,0,0,0,47.869,58.035Z"
        transform="translate(41.835 27.333)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M44.9,90.229A66.484,66.484,0,0,0,65.825,131.3c12.687,11.735,19.445,12.386,18.507,30.164-.693,13.108-7.406,24.175.155,37.441,7.34,12.878,22.912,21.732,38.217,21.732,19.72,0,38.115-13.239,57.252-7.44,9.542,2.891,16.984,10,25.094,15.682s18.776,10.221,27.633,6.816c10.64-4.091,12.736-17.419,9.7-28.151s-9.658-20.742-11.41-31.634c-2.979-18.5,8.533-35.264,20.847-49.105s26.428-27.558,30.049-45.535-9.13-41.231-29.244-42.332c-25.415-1.39-38.591,31-63.962,30.768-18.246-.168-31.554-16.8-46.977-26.831-29.35-19.09-73.475-11.461-90.11,15.58C45.736,67.949,43.711,79.09,44.9,90.229Z"
        transform="translate(44.963 30.018)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

const waveGallery_svg = (
  <svg
    id="Group_148"
    data-name="Group 148"
    xmlns="http://www.w3.org/2000/svg"
    width="711.174"
    height="693.621"
    viewBox="0 0 711.174 693.621">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="711.174"
          height="693.621"
          fill="none"
          stroke="#5cb4e1"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_111"
      data-name="Group 111"
      clipPath="url(#clip-path)">
      <path
        id="Path_111"
        data-name="Path 111"
        d="M40.157,104.7c-13.117,17.154-20.778,36.506-18.323,58.036C26.639,204.887,70,236.14,73.9,278.378c5.527,59.954-69.039,102.64-73.22,162.7-2.954,42.4,32.017,80.4,72.891,96.626,46.044,18.281,102.134,2.37,143.707,26.384,48.641,28.1,46.833,106.292,101.573,123.8,31.307,10.013,65.673-11.807,80.713-40.022s16.024-61.1,18.656-92.706c3.413-41.009,5.613-75.4,48.009-97.489,29.11-15.165,62.931-19.2,94.242-29.564,55.7-18.424,103.5-57.934,130.781-108.09,21.63-39.77,26.192-96.818-12.172-122.406-22.955-15.308-53.842-13.793-80.873-6.987-51.508,12.971-108.144,36.713-159.986,8.178-44.259-24.364-46.044-80.67-69.651-120.439C348.187,44.03,316.339,14.119,276.52,4.231,220.089-9.781,162.355,18.148,112.345,46.91,86,62.063,58.02,81.343,40.157,104.7Z"
        transform="translate(1.463 1.384)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Path_112"
        data-name="Path 112"
        d="M38.871,102.761c-12.345,16.276-19.621,34.6-17.593,54.934,2.816,28.464,23.716,51.942,36.881,77.606,5.276,10.277,9.308,20.9,10.43,32.345,5.547,56.705-63.257,97.594-63.08,153.84.063,21.338,9.14,39.811,21.406,58.115,11.929,17.8,28.012,32.376,48.174,41.729,40.552,18.82,91.543,7.454,131.319,24.673q3.336,1.441,6.559,3.158C260.513,574.52,262,643.5,315.015,660.4c30.154,9.614,62.716-8.962,77.833-33.6s17.9-53.736,21.861-83.066c4.7-34.528,7.061-65.656,35.5-87.709a94.569,94.569,0,0,1,9.661-6.576c25.874-15.444,55.7-20.644,83.859-31.829,50.159-19.921,95.136-59.178,122.595-106.985,11.317-19.7,19.849-42.7,20.331-65.366.471-24.8-9.607-48.656-31.256-61.507-22.861-13.574-52.05-11.867-77.943-4.7-42.365,11.739-87.958,31.29-132.119,19.559A100.636,100.636,0,0,1,423.7,190.1c-42.8-22.837-46.589-76.328-70.274-114.063-20.206-32.18-50.9-60.018-88.958-69.194C210.445-6.169,155.17,19.808,107.518,47.288,82.338,61.808,55.853,80.323,38.871,102.761Z"
        transform="translate(16.101 9.384)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Path_113"
        data-name="Path 113"
        d="M37.859,100.823C26.283,116.225,19.4,133.523,21,152.66,23.2,179.5,41.926,201.926,53.91,226.312c4.805,9.749,8.52,19.811,9.646,30.6,5.57,53.457-56.969,92.759-52.944,144.984,1.671,21.654,11.807,38.334,22.885,58.194,10.4,18.658,23.512,34.218,43.381,44.868,37.524,20.109,86.867,10.951,125.655,26.448q3.248,1.3,6.4,2.826c46.456,22.619,51.238,82.384,102.523,98.676,29,9.218,59.762-6.113,74.95-27.179s19.774-46.368,25.07-73.43c6.312-31.871,8.936-62.4,33.841-84.438a89.306,89.306,0,0,1,8.461-6.644c22.642-15.726,48.476-22.087,73.483-34.1,44.624-21.428,86.914-60.357,114.4-105.874,12.4-20.539,23.022-43.809,24.167-67.721,1.13-25.833-10.638-51.4-33.841-63.624-22.661-11.931-50.257-9.941-75.013-2.4-40.513,12.346-83.71,32.376-126.533,21.793a96.073,96.073,0,0,1-21-7.876c-41.333-21.307-47.115-71.987-70.894-107.683-20.017-30.043-49.567-55.8-85.871-64.273-51.61-12.014-104.417,12.007-149.716,38.2C78.941,61.559,53.953,79.3,37.859,100.823Z"
        transform="translate(30.47 17.385)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M37.061,98.884c-10.8,14.528-17.3,30.8-16.13,48.739,1.593,25.219,18.142,46.587,28.949,69.7,4.331,9.218,7.728,18.718,8.861,28.863,5.59,50.2-50.132,88.067-42.808,136.124C19.3,404.4,30.447,419.124,40.289,440.586c8.928,19.476,19.005,35.986,38.6,48.008,34.531,21.187,82.187,14.449,119.982,28.227q3.172,1.153,6.249,2.495c45.366,19.879,53.438,70.427,103,86.112,27.851,8.822,56.808-3.264,72.067-20.757s21.653-39,28.282-63.79c7.92-29.217,10.811-59.174,32.185-81.163A87.28,87.28,0,0,1,447.908,433c19.452-15.956,41.258-23.542,63.1-36.363,39.109-22.958,78.822-61.484,106.218-104.766,13.525-21.371,26.262-44.917,28-70.076,1.859-26.858-11.588-54.2-36.426-65.739-22.359-10.39-48.46-8.016-72.079-.113C498.055,168.9,457.26,189.4,415.77,179.965a91.018,91.018,0,0,1-20.359-7.236c-39.874-19.777-47.629-67.645-71.518-101.3-19.813-27.91-48.237-51.591-82.784-59.347-49.2-11.019-99.541,11.049-142.487,35.96C75.762,61.308,52.273,78.289,37.061,98.884Z"
        transform="translate(44.625 25.384)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M36.442,96.947c-10,13.672-16.142,28.893-15.4,45.637.981,23.6,15.357,43.915,24.987,65.757,3.852,8.69,6.935,17.626,8.073,27.119,5.609,46.96-42.737,83.451-32.668,127.268C26.623,385.32,38.7,397.979,47.26,421.08c7.5,20.252,14.51,37.667,33.809,51.147,31.609,22.069,77.508,17.946,114.314,30q3.089,1.006,6.092,2.167c44.271,17.139,55.639,58.469,103.472,73.551,26.7,8.423,53.854-.418,69.184-14.336s23.477-31.644,31.491-54.154c9.465-26.587,12.69-55.971,30.53-77.888a89.242,89.242,0,0,1,6.065-6.791c16.236-16.227,34.045-25.008,52.717-38.624,33.609-24.514,70.831-62.562,98.031-103.658,14.682-22.189,29.443-46.029,31.836-72.435,2.522-27.884-12.478-57.06-39.011-67.853-21.99-8.943-46.672-6.09-69.153,2.178-36.814,13.559-75.209,34.55-115.361,26.255a86.941,86.941,0,0,1-19.723-6.595c-38.411-18.251-48.119-63.315-72.138-94.93-19.594-25.781-46.911-47.374-79.7-54.422C182.929,4.678,135.049,24.79,94.462,48.419,72.762,61.059,50.81,77.3,36.442,96.947Z"
        transform="translate(58.596 33.385)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M38.573,95.008C29.347,107.806,23.588,122,23.9,137.547c.369,21.978,12.572,41.239,21.022,61.8a99.7,99.7,0,0,1,7.288,25.377c5.633,43.711-34.821,78.83-22.528,118.412,7.182,23.143,20.076,33.668,27.3,58.431,6.131,21.01,10.054,39.264,29.024,54.282,28.777,22.781,72.828,21.447,108.646,31.78q3,.865,5.939,1.839c43.177,14.4,57.836,46.515,103.943,60.987,25.548,8.027,50.9,2.434,66.3-7.914s25.348-24.281,34.7-44.514c11.066-23.942,14.553-52.8,28.875-74.617,1.526-2.325,3.1-4.628,4.864-6.859,13.019-16.506,26.835-26.5,42.337-40.893,28.11-26.1,62.923-63.587,89.837-102.546,15.883-22.988,32.625-47.137,35.673-74.791,3.189-28.912-13.306-60.007-41.6-69.967-21.563-7.594-44.875-4.168-66.218,4.469-34.963,14.162-70.961,35.635-109.779,28.483a82.236,82.236,0,0,1-19.08-5.954c-36.948-16.725-48.59-58.989-72.761-88.55-19.351-23.663-45.581-43.161-76.614-49.5C176.7,8.29,131.285,26.45,93.051,48.794,72.508,60.8,52.067,76.286,38.573,95.008Z"
        transform="translate(69.822 41.384)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M41.424,93.071c-8.449,11.924-13.823,25.091-13.937,39.438-.243,20.361,9.783,38.564,17.056,57.859A108.954,108.954,0,0,1,51.043,214C56.7,254.462,24.6,288.138,38.651,323.555c9.391,23.67,22.944,32.07,28.777,58.51,4.8,21.76,5.653,40.772,24.234,57.421,26.05,23.339,68.144,24.948,102.978,33.555,1.946.482,3.876.98,5.782,1.507,42.086,11.664,60.04,34.561,104.421,48.426,24.391,7.631,47.939,5.283,63.414-1.489s27.227-16.921,37.913-34.878c12.674-21.3,16.4-49.662,27.223-71.342,1.169-2.344,2.342-4.669,3.66-6.93,9.8-16.8,19.621-28.015,31.958-43.157,22.6-27.744,55.094-64.57,81.65-101.438,17.118-23.765,35.8-48.249,39.509-77.146,3.84-29.941-14.09-63.063-44.181-72.081-21.1-6.327-43.087-2.242-63.288,6.757-33.115,14.773-66.713,36.721-104.194,30.714a78.066,78.066,0,0,1-18.44-5.314c-35.488-15.195-49.037-54.678-73.385-82.169-19.088-21.556-44.251-38.948-73.526-44.574-41.961-8.019-84.914,8.185-120.794,29.244C72.978,60.555,54.036,75.272,41.424,93.071Z"
        transform="translate(80.323 49.384)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M44.326,91.132c-7.681,11.049-12.639,23.188-13.208,36.34-.812,18.741,7,35.888,13.094,53.909a127.115,127.115,0,0,1,5.711,21.892c5.676,37.211-17.675,69.307-2.252,100.7,11.843,24.1,25.862,30.473,30.248,58.59,3.511,22.506,1.349,42.193,19.449,60.561,23.446,23.787,63.465,28.445,97.305,35.33,1.895.388,3.77.776,5.629,1.18,41,8.92,62.241,22.6,104.9,35.862,23.242,7.236,44.985,8.133,60.534,4.933s29.106-9.557,41.121-25.242c14.286-18.647,18.193-46.549,25.564-68.068.812-2.367,1.585-4.711,2.463-7a292.343,292.343,0,0,1,21.575-45.426c17.056-29.429,47.327-65.509,73.464-100.33,18.4-24.5,38.968-49.357,43.341-79.5,4.5-30.974-14.839-66.24-46.766-74.2-20.617-5.14-41.29-.317-60.358,9.048-31.264,15.379-62.46,37.81-98.608,32.948a74.085,74.085,0,0,1-17.8-4.677c-34.025-13.665-49.457-50.386-74-75.793C256.918,42.729,232.8,27.459,205.285,22.544,165.732,15.52,125.243,29.772,91.72,49.55,73.5,60.305,56.055,74.256,44.326,91.132Z"
        transform="translate(90.778 57.383)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M47.315,89.2C40.4,99.367,35.829,110.48,34.833,122.438c-1.428,17.121,4.217,33.208,9.132,49.956a168.97,168.97,0,0,1,4.923,20.15c5.7,33.966-8.622,64.284,7.888,91.843,14.592,24.356,28.828,28.878,31.719,58.665,2.259,23.259-2.84,43.534,14.663,63.7,20.974,24.153,58.785,31.942,91.637,37.109q2.76.43,5.476.848c39.9,6.18,64.441,10.646,105.367,23.3,22.088,6.836,42.031,10.978,57.651,11.355s30.993-2.19,44.33-15.606c15.906-15.994,19.943-43.455,23.913-64.793.443-2.389.82-4.752,1.263-7.074,3.326-17.4,5.107-31.113,11.2-47.687,11.438-31.143,39.6-66.409,65.273-99.222,19.723-25.212,42.082-50.48,47.182-81.853,5.088-32.014-15.581-69.56-49.355-76.313-20.131-4.025-39.5,1.605-57.428,11.34-29.412,15.986-58.212,38.9-93.022,35.176a70.342,70.342,0,0,1-17.162-4.036c-32.566-12.135-49.849-46.116-74.629-69.413-18.5-17.392-41.592-30.518-67.352-34.723-37.14-6.022-75.162,6.275-106.339,24.767C74.1,60.057,58.157,73.235,47.315,89.2Z"
        transform="translate(101.146 65.383)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M50.465,87.257A71.309,71.309,0,0,0,38.717,117.4c-2.075,15.5,1.432,30.537,5.166,46.01,1.479,6.045,2.977,12.161,4.142,18.409,5.715,30.714.6,59,18.025,82.983,17.672,24.322,31.84,27.3,33.19,58.748,1.036,24.021-6.884,44.8,9.877,66.835,18.625,24.477,54.105,35.439,85.969,38.884q2.671.288,5.315.516c38.811,3.445,66.642-1.3,105.845,10.74,20.935,6.44,39.078,13.831,54.768,17.776s32.884,5.186,47.543-5.966c17.526-13.337,21.626-40.35,22.253-61.518.071-2.416.055-4.794.067-7.145.067-17.693-2.209-32.666.812-49.952,5.743-32.854,31.931-67.269,57.086-98.114,21.1-25.871,45.256-51.591,51.01-84.208,5.751-33.043-16.3-73.038-51.936-78.431C468.2,70,450.145,76.5,433.356,86.6c-27.564,16.593-53.96,39.981-87.44,37.41a66.929,66.929,0,0,1-16.522-3.4c-31.1-10.608-50.206-41.891-75.248-63.037-18.17-15.346-40.266-26.3-64.269-29.8C155.153,22.751,119.59,33.1,90.771,50.3,74.872,59.8,60.413,72.213,50.465,87.257Z"
        transform="translate(111.348 73.381)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M53.964,85.322a76.442,76.442,0,0,0-11.015,27.043c-2.691,13.876-1.357,27.861,1.2,42.061,1,5.517,2.181,11.068,3.354,16.665,5.739,27.465,9.885,53.419,28.165,74.127,21.08,23.881,34.888,25.735,34.665,58.827-.169,24.8-10.764,46.022,5.088,69.971,16.416,24.8,49.425,38.94,80.3,40.663q2.589.147,5.158.188c37.72.7,68.847-13.262,106.32-1.824,19.786,6.045,36.124,16.68,51.885,24.2s34.79,12.587,50.751,3.671c19.17-10.642,23.265-37.211,20.6-58.247-.31-2.431-.714-4.831-1.138-7.213-3.2-17.984-9.555-34.2-9.567-52.221-.016-34.524,24.285-68.086,48.9-97,22.54-26.482,48.429-52.7,54.843-86.564,6.414-34.071-17.048-76.686-54.517-80.545-19.194-1.975-35.916,5.457-51.567,15.922-25.709,17.2-49.708,41.066-81.85,39.638a63.711,63.711,0,0,1-15.883-2.755c-29.64-9.082-50.536-37.708-75.872-56.66A129.315,129.315,0,0,0,182.6,30.4c-32.315-4.025-65.414,4.364-91.876,20.29C75.99,59.556,62.986,71.171,53.964,85.322Z"
        transform="translate(121.206 81.38)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M45.205,145.439c3.13,29.4,17.723,58.782,40.87,80.195,24.776,22.917,37.971,24.187,36.139,58.9-1.353,25.6-14.463,47.209.3,73.114,14.333,25.148,44.742,42.438,74.629,42.438,38.509,0,74.429-25.852,111.8-14.528,18.633,5.645,33.166,19.529,49,30.623s36.665,19.958,53.96,13.311c20.778-7.989,24.87-34.015,18.946-54.972s-18.86-40.5-22.281-61.774c-5.817-36.129,16.663-68.863,40.709-95.891s51.606-53.815,58.679-88.919-17.828-80.515-57.106-82.663c-49.629-2.713-75.358,60.538-124.9,60.082-35.63-.328-61.617-32.809-91.735-52.394C176.9,15.684,90.739,30.581,58.256,83.386,46.845,101.931,42.891,123.686,45.205,145.439Z"
        transform="translate(130.267 89.376)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

const waveOurHistory_svg = (
  <svg
    id="Group_151"
    data-name="Group 151"
    xmlns="http://www.w3.org/2000/svg"
    width="386"
    height="376.473"
    viewBox="0 0 386 376.473">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_119"
          data-name="Rectangle 119"
          width="386"
          height="376.473"
          fill="none"
          stroke="#5cb4e1"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_111"
      data-name="Group 111"
      clipPath="url(#clip-path)">
      <path
        id="Path_111"
        data-name="Path 111"
        d="M22.024,57.056c-7.12,9.311-11.278,19.814-9.945,31.5,2.608,22.878,26.143,39.841,28.257,62.766,3,32.541-37.472,55.71-39.741,88.306-1.6,23.011,17.378,43.637,39.563,52.445,24.991,9.922,55.435,1.287,78,14.32,26.4,15.251,25.419,57.691,55.13,67.193,16.992,5.435,35.645-6.408,43.808-21.722s8.7-33.165,10.126-50.318c1.852-22.258,3.047-40.927,26.058-52.913,15.8-8.231,34.157-10.424,51.151-16.046,30.231-10,56.178-31.444,70.984-58.667,11.74-21.585,14.216-52.549-6.607-66.438-12.459-8.309-29.224-7.486-43.9-3.792-27.957,7.04-58.7,19.927-86.834,4.439-24.022-13.224-24.991-43.785-37.8-65.37-11.061-18.63-28.347-34.864-49.959-40.232-30.629-7.6-61.965,7.554-89.108,23.165C46.909,33.914,31.72,44.379,22.024,57.056Z"
        transform="translate(0.565 0.523)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Path_112"
        data-name="Path 112"
        d="M23.617,57.325c-6.7,8.834-10.65,18.781-9.549,29.816C15.6,102.59,26.94,115.333,34.086,129.263a48.911,48.911,0,0,1,5.661,17.556c3.011,30.778-34.334,52.971-34.238,83.5.034,11.581,4.961,21.608,11.618,31.543a63.626,63.626,0,0,0,26.147,22.649c22.01,10.215,49.686,4.046,71.275,13.391q1.811.782,3.56,1.714c25.807,13.764,26.614,51.2,55.388,60.375,16.366,5.218,34.04-4.864,42.245-18.239s9.713-29.166,11.865-45.085c2.553-18.74,3.832-35.636,19.266-47.606a51.328,51.328,0,0,1,5.244-3.569c14.043-8.382,30.231-11.2,45.515-17.276,27.225-10.812,51.637-32.119,66.54-58.068,6.142-10.692,10.773-23.175,11.035-35.478.256-13.463-5.214-26.409-16.964-33.384-12.408-7.368-28.251-6.441-42.3-2.549-22.994,6.372-47.74,16.983-71.709,10.616a54.622,54.622,0,0,1-11.744-4.621c-23.23-12.4-25.287-41.428-38.142-61.909C183.377,25.357,166.719,10.247,146.06,5.266,116.741-1.8,86.74,12.3,60.876,27.216,47.209,35.1,32.834,45.146,23.617,57.325Z"
        transform="translate(6.22 3.543)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Path_113"
        data-name="Path 113"
        d="M25.315,57.595c-6.283,8.36-10.019,17.748-9.153,28.135,1.2,14.57,11.361,26.74,17.865,39.976a49.116,49.116,0,0,1,5.235,16.611c3.023,29.014-30.921,50.346-28.736,78.692.907,11.753,6.409,20.806,12.421,31.586,5.646,10.127,12.762,18.573,23.545,24.353,20.367,10.914,47.148,5.944,68.2,14.355q1.763.706,3.475,1.534c25.215,12.277,27.81,44.715,55.646,53.558,15.742,5,32.437-3.318,40.68-14.752s10.733-25.167,13.607-39.855c3.426-17.3,4.85-33.87,18.368-45.83a48.474,48.474,0,0,1,4.592-3.606c12.289-8.536,26.311-11.988,39.884-18.507,24.22-11.63,47.174-32.76,62.095-57.464,6.732-11.148,12.5-23.778,13.117-36.756.613-14.021-5.774-27.9-18.368-34.533-12.3-6.476-27.278-5.4-40.714-1.3-21.989,6.7-45.435,17.572-68.678,11.829a52.145,52.145,0,0,1-11.4-4.275c-22.434-11.565-25.572-39.072-38.479-58.446-10.865-16.306-26.9-30.289-46.608-34.885C113.9,1.486,85.238,14.524,60.652,28.742,47.613,36.283,34.051,45.915,25.315,57.595Z"
        transform="translate(11.771 6.565)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M27.1,57.863c-5.863,7.885-9.391,16.715-8.755,26.454C19.206,98,28.189,109.6,34.054,122.149a49.893,49.893,0,0,1,4.81,15.666c3.034,27.249-27.21,47.8-23.235,73.883,1.827,11.986,7.878,19.98,13.219,31.629C33.695,253.9,39.164,262.859,49.8,269.384c18.742,11.5,44.608,7.842,65.122,15.32q1.721.626,3.392,1.354c24.623,10.79,29,38.225,55.9,46.738,15.116,4.788,30.833-1.771,39.115-11.266s11.753-21.166,15.351-34.623c4.3-15.858,5.868-32.117,17.469-44.053a47.372,47.372,0,0,1,3.941-3.645c10.558-8.66,22.394-12.778,34.248-19.736,21.227-12.461,42.782-33.371,57.651-56.863,7.341-11.6,14.254-24.38,15.2-38.035C358.2,110,350.9,95.158,337.416,88.894c-12.136-5.639-26.3-4.351-39.122-.061-20.986,7.03-43.129,18.161-65.648,13.038a49.4,49.4,0,0,1-11.05-3.927c-21.642-10.734-25.851-36.716-38.817-54.983-10.754-15.149-26.181-28-44.932-32.212-26.7-5.981-54.028,6-77.337,19.518C48.1,37.468,35.353,46.685,27.1,57.863Z"
        transform="translate(17.239 9.585)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M28.946,58.133c-5.427,7.421-8.761,15.682-8.359,24.77.532,12.811,8.335,23.835,13.562,35.691a51.457,51.457,0,0,1,4.382,14.719c3.045,25.488-23.2,45.294-17.731,69.076,2.817,12.262,9.372,19.133,14.018,31.672,4.073,10.992,7.875,20.444,18.351,27.761,17.156,11.978,42.068,9.74,62.046,16.284q1.677.546,3.306,1.176c24.029,9.3,30.2,31.735,56.161,39.921,14.491,4.572,29.23-.227,37.551-7.781s12.743-17.175,17.092-29.393c5.137-14.43,6.888-30.379,16.571-42.275a48.434,48.434,0,0,1,3.292-3.686C258,227.26,267.665,222.494,277.8,215.1c18.242-13.306,38.445-33.956,53.208-56.262,7.969-12.044,15.981-24.983,17.28-39.315,1.369-15.134-6.773-30.97-21.174-36.828-11.936-4.854-25.332-3.305-37.534,1.182-19.981,7.359-40.821,18.753-62.614,14.251a47.188,47.188,0,0,1-10.705-3.58c-20.848-9.906-26.117-34.365-39.154-51.525-10.635-13.993-25.462-25.713-43.259-29.538-25.394-5.437-51.381,5.48-73.411,18.3C48.66,38.654,36.745,47.47,28.946,58.133Z"
        transform="translate(22.637 12.607)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M31.859,58.4C26.852,65.348,23.726,73.051,23.9,81.49c.2,11.929,6.824,22.383,11.41,33.545a54.113,54.113,0,0,1,3.956,13.774c3.057,23.725-18.9,42.786-12.227,64.27,3.9,12.561,10.9,18.274,14.816,31.714,3.328,11.4,5.457,21.311,15.753,29.462,15.619,12.365,39.528,11.641,58.969,17.249q1.629.469,3.223,1c23.435,7.816,31.391,25.247,56.416,33.1,13.867,4.357,27.627,1.321,35.986-4.3s13.758-13.179,18.836-24.161c6.006-12.995,7.9-28.659,15.672-40.5.828-1.262,1.684-2.512,2.64-3.723,7.066-8.959,14.565-14.381,22.979-22.2,15.257-14.169,34.153-34.513,48.76-55.658,8.621-12.477,17.708-25.584,19.362-40.594,1.731-15.693-7.222-32.57-22.577-37.976-11.7-4.122-24.357-2.262-35.941,2.426-18.976,7.687-38.515,19.342-59.584,15.459a44.635,44.635,0,0,1-10.356-3.232C191.936,82.079,185.617,59.14,172.5,43.1c-10.5-12.843-24.74-23.426-41.583-26.865-24.084-4.9-48.735,4.96-69.487,17.088C50.278,39.837,39.183,48.24,31.859,58.4Z"
        transform="translate(26.974 15.627)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M35.05,58.671c-4.586,6.472-7.5,13.619-7.565,21.405-.132,11.051,5.31,20.931,9.257,31.4a59.137,59.137,0,0,1,3.528,12.829c3.068,21.96-14.35,40.238-6.726,59.461,5.1,12.847,12.453,17.407,15.619,31.757,2.6,11.81,3.068,22.13,13.153,31.166,14.139,12.667,36.986,13.541,55.893,18.213,1.056.262,2.1.532,3.138.818,22.843,6.331,32.588,18.759,56.676,26.284,13.239,4.142,26.019,2.868,34.419-.808s14.778-9.184,20.578-18.93c6.879-11.559,8.9-26.955,14.776-38.722.635-1.272,1.271-2.534,1.986-3.762,5.321-9.116,10.65-15.206,17.346-23.424,12.268-15.059,29.9-35.047,44.317-55.057,9.291-12.9,19.43-26.188,21.444-41.872,2.084-16.251-7.648-34.228-23.98-39.123-11.452-3.434-23.386-1.217-34.351,3.667C256.585,82,238.349,93.908,218.006,90.648A42.372,42.372,0,0,1,208,87.764c-19.262-8.247-26.616-29.677-39.831-44.6-10.36-11.7-24.018-21.14-39.907-24.193C105.484,14.619,82.171,23.414,62.7,34.844,52.176,41.023,41.895,49.011,35.05,58.671Z"
        transform="translate(31.03 18.648)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M38.26,58.94c-4.169,6-6.86,12.586-7.169,19.724-.441,10.172,3.8,19.479,7.107,29.26a68.994,68.994,0,0,1,3.1,11.882c3.081,20.2-9.594,37.618-1.222,54.654C46.5,187.543,54.114,191,56.494,206.261c1.906,12.215.732,22.9,10.556,32.87,12.726,12.911,34.446,15.439,52.814,19.176,1.028.211,2.046.421,3.055.64,22.251,4.842,33.782,12.269,56.934,19.464,12.615,3.927,24.416,4.414,32.856,2.677s15.8-5.187,22.319-13.7c7.754-10.121,9.875-25.265,13.875-36.945.441-1.285.86-2.557,1.337-3.8a158.674,158.674,0,0,1,11.71-24.656c9.257-15.973,25.687-35.556,39.873-54.456,9.985-13.3,21.15-26.789,23.524-43.149,2.44-16.811-8.054-35.953-25.383-40.273-11.19-2.79-22.411-.172-32.76,4.911-16.969,8.347-33.9,20.522-53.521,17.883a40.211,40.211,0,0,1-9.662-2.538c-18.468-7.417-26.843-27.347-40.167-41.138-10.207-10.563-23.3-18.851-38.232-21.518C104.155,17.9,82.179,25.636,63.984,36.371,54.094,42.209,44.626,49.781,38.26,58.94Z"
        transform="translate(35.069 21.668)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M41.5,59.21A37.6,37.6,0,0,0,34.73,77.253c-.775,9.292,2.289,18.024,4.957,27.114A91.712,91.712,0,0,1,42.358,115.3c3.094,18.436-4.68,34.891,4.282,49.849,7.92,13.22,15.647,15.674,17.216,31.841,1.226,12.624-1.541,23.629,7.958,34.574,11.384,13.109,31.906,17.337,49.737,20.141l2.972.46c21.655,3.355,34.977,5.778,57.189,12.647,11.989,3.71,22.813,5.958,31.291,6.163s16.822-1.188,24.061-8.47c8.633-8.681,10.824-23.586,12.979-35.167.241-1.3.445-2.579.686-3.839,1.805-9.442,2.772-16.887,6.076-25.883,6.208-16.9,21.5-36.045,35.428-53.854,10.705-13.684,22.841-27.4,25.609-44.427,2.761-17.376-8.457-37.755-26.788-41.42-10.926-2.185-21.44.871-31.17,6.155-15.964,8.677-31.6,21.111-50.489,19.092a38.179,38.179,0,0,1-9.315-2.191C182.4,74.389,173.024,55.946,159.575,43.3,149.536,33.861,137,26.737,123.018,24.454,102.86,21.186,82.223,27.86,65.3,37.9,56.044,43.395,47.389,50.548,41.5,59.21Z"
        transform="translate(39.075 24.689)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M44.811,59.479a38.7,38.7,0,0,0-6.377,16.359c-1.126,8.413.777,16.574,2.8,24.973.8,3.281,1.616,6.6,2.248,9.992,3.1,16.67.328,32.025,9.783,45.04,9.592,13.2,17.282,14.817,18.014,31.886.562,13.038-3.737,24.318,5.361,36.276,10.109,13.285,29.366,19.235,46.661,21.1q1.45.156,2.885.28c21.065,1.87,36.171-.708,57.449,5.83,11.363,3.5,21.21,7.507,29.726,9.648s17.848,2.815,25.8-3.238c9.513-7.239,11.738-21.9,12.078-33.39.038-1.311.03-2.6.036-3.878.036-9.6-1.2-17.73.441-27.112,3.117-17.832,17.331-36.511,30.985-53.253,11.454-14.042,24.563-28,27.686-45.705,3.121-17.934-8.848-39.643-28.189-42.57-10.665-1.612-20.465,1.919-29.577,7.4-14.961,9.006-29.288,21.7-47.459,20.305a36.327,36.327,0,0,1-8.968-1.843c-16.879-5.758-27.25-22.737-40.842-34.214A70.1,70.1,0,0,0,120.478,27.2c-18.847-2.729-38.149,2.886-53.791,12.226C58.058,44.578,50.21,51.314,44.811,59.479Z"
        transform="translate(43.016 27.709)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M48.252,59.75a41.49,41.49,0,0,0-5.978,14.678c-1.461,7.531-.737,15.122.651,22.829.545,2.995,1.184,6.007,1.82,9.045,3.115,14.907,5.365,28.994,15.287,40.234C71.474,159.5,78.968,160.5,78.847,178.465c-.092,13.461-5.842,24.979,2.761,37.978,8.91,13.461,26.826,21.136,43.584,22.07q1.405.08,2.8.1c20.473.382,37.367-7.2,57.707-.99,10.739,3.281,19.607,9.053,28.161,13.136s18.883,6.832,27.546,1.992c10.4-5.776,12.628-20.2,11.182-31.614-.168-1.319-.388-2.622-.617-3.915-1.737-9.761-5.186-18.562-5.193-28.344-.008-18.738,13.181-36.955,26.541-52.65,12.234-14.373,26.286-28.605,29.767-46.984,3.481-18.493-9.253-41.623-29.59-43.717-10.418-1.072-19.494,2.962-27.989,8.642-13.954,9.335-26.98,22.289-44.425,21.514a34.58,34.58,0,0,1-8.621-1.5c-16.087-4.93-27.429-20.467-41.181-30.753a70.188,70.188,0,0,0-33.207-13.5c-17.539-2.185-35.5,2.369-49.867,11.013A60.252,60.252,0,0,0,48.252,59.75Z"
        transform="translate(46.824 30.73)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M44.915,93.7A70.466,70.466,0,0,0,67.1,137.227c13.447,12.438,20.609,13.128,19.615,31.97-.735,13.893-7.85,25.623.164,39.684,7.78,13.649,24.284,23.034,40.506,23.034,20.9,0,40.4-14.032,60.681-7.885,10.113,3.064,18,10.6,26.6,16.621s19.9,10.833,29.288,7.225c11.278-4.336,13.5-18.462,10.283-29.837s-10.237-21.984-12.093-33.529c-3.157-19.61,9.044-37.376,22.1-52.046s28.01-29.209,31.849-48.262-9.677-43.7-31-44.867c-26.937-1.473-40.9,32.858-67.792,32.61-19.338-.178-33.444-17.808-49.791-28.438C116.4,23.273,69.63,31.359,52,60.02,45.805,70.085,43.659,81.893,44.915,93.7Z"
        transform="translate(50.325 33.749)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

export { waveWeTalk_svg, waveHero_svg, waveOurHistory_svg, waveGallery_svg };
