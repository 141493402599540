import "./mvv.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

const MVV: React.FC = () => {
  const { sanityPageAboutUs: data }: Queries.mvvQuery = useStaticQuery(graphql`
    query mvv {
      sanityPageAboutUs {
        mission: _rawMission
        vision: _rawVision
        values: _rawValues
      }
    }
  `);
  return (
    <section className={"o-mvv"}>
      <div className={"c-mvv"}>
        <div className="m-mvv-item">
          <h2 className={"a-mvv-title"}>Misión</h2>
          <BlockContent blocks={data?.mission} />
        </div>
        <div className="m-mvv-item">
          <h2 className={"a-mvv-title"}>Visión</h2>
          <BlockContent blocks={data?.vision} />
        </div>
        <div className="m-mvv-item">
          <h2 className={"a-mvv-title"}>Valores</h2>
          <BlockContent blocks={data?.values} />
        </div>
      </div>
    </section>
  );
};

export default MVV;
