import "../HeroService/hero-service.scss";
import "./hero-about-us.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";

import { right_svg } from "../../../assets/svg/controls_elements";
import { Image } from "../../../types/shared";
import ModalFormInfo from "../../ModalFormInfo/ModalFormInfo";

const HeroAboutUs: React.FC = () => {
  const data: Queries.heroAboutUsQuery = useStaticQuery(graphql`
    query heroAboutUs {
      sanityPageAboutUs {
        hero {
          photo {
            image {
              asset {
                gatsbyImageData
                _id
              }
            }
            alt
          }
          title
          subtitle
          text: _rawText
        }
      }
    }
  `);
  const { sanityPageAboutUs } = data;

  const { ModalForm, toggleModal } = ModalFormInfo();

  return (
    <>
      <ModalForm />
      <section className={"o-hero-service"}>
        <div className="m-hero-service__first-row-wrapper">
          <div className={"c-hero-service"}>
            <div className="m-hero-service__first-row">
              <div className="m-hero-service__title">
                <h1 className={`a-hero-service__title`}>{sanityPageAboutUs?.hero?.title}</h1>
              </div>
              <SanityImage
                {...(sanityPageAboutUs?.hero?.photo?.image as unknown as Image)}
                alt={sanityPageAboutUs?.hero?.photo?.alt}
                className={"m-hero-service__photo"}
                style={{ maxWidth: "960" }}
                width={960}
                height={895}
              />
            </div>
          </div>
        </div>
        <div className="m-hero-service__second-row-wrapper">
          <div className="m-hero-about-us__second-row">
            <div className={"m-hero-about-us__column-left"}>
              <h2 className={"a-hero-service__subtitle"}>{sanityPageAboutUs?.hero?.subtitle}</h2>
              <button
                onClick={() => {
                  toggleModal();
                }}
                className={"m-button m-button--light"}>
                Contacta con nosotros {right_svg}
              </button>
              <div className="m-hero-about-us__resume">
                <BlockContent blocks={sanityPageAboutUs?.hero?.text} />
                <div>
                  <p><strong>Canal de denuncias</strong></p>
                  <p>Cualquier delito o incumplimiento del código ético puede ser denunciado a través del siguiente enlace</p>
                  <a className={"m-button m-button--light"} href={"/CANAL_DE_DENUNCIAS.pdf"} target={"_blank"} rel={"noreferrer"}>
                    Ir al canal {right_svg}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroAboutUs;
