import "./infrastructure-goals.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import { building_svg, ice_svg, structure_svg, truck_svg } from "./elements_infrastrutureGoals";

const InfrastructureGoals: React.FC = () => {
  const { sanityPageAboutUs: data }: Queries.infrastructureGoalQuery = useStaticQuery(graphql`
    query infrastructureGoal {
      sanityPageAboutUs {
        infrastructureGoals {
          buildUpMeters
          coldMeters
          palletCapacity
          vehicles
        }
      }
    }
  `);
  return (
    <section className={"o-infrastructure-goals"}>
      <div className={"c-infrastructure-goals"}>
        <div className="m-infrastructure-goals__item">
          <div className={"m-infrastructure-goals__in-item"}>
            {building_svg}
            <p className={"m-infrastructure-goals__number"}> {data?.infrastructureGoals?.buildUpMeters}</p>
            <p className={"m-infrastructure-goals__text"}>m² edificados</p>
          </div>
        </div>
        <div className="m-infrastructure-goals__item">
          <div className={"m-infrastructure-goals__in-item"}>
            {ice_svg}
            <p className={"m-infrastructure-goals__number"}> {data?.infrastructureGoals?.coldMeters}</p>
            <p className={"m-infrastructure-goals__text"}>m² de frío</p>
          </div>
        </div>
        <div className="m-infrastructure-goals__item">
          <div className={"m-infrastructure-goals__in-item"}>
            {structure_svg}
            <p className={"m-infrastructure-goals__number"}> {data?.infrastructureGoals?.palletCapacity}</p>
            <p className={"m-infrastructure-goals__text"}>capacidad palets</p>
          </div>
        </div>
        <div className="m-infrastructure-goals__item">
          <div className={"m-infrastructure-goals__in-item"}>
            {truck_svg}
            <p className={"m-infrastructure-goals__number"}> {data?.infrastructureGoals?.vehicles}</p>
            <p className={"m-infrastructure-goals__text"}>vehículos</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfrastructureGoals;
